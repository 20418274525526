const DATA = [
  {
    name: 'telegram',
    href: 'https://t.me/Cosmetology_Clinic_Bot',
    target: '_blank',
    rel: 'noreferrer',
  },
  {
    name: 'viber',
    href: 'viber://pa?chatURI=cosmetology_clinic',
  },
  {
    name: 'instagram',
    href: 'https://www.instagram.com/_cosmetology.ua/',
    target: '_blank',
    rel: 'noreferrer',
  },
]

export default DATA
